import {Link, graphql} from 'gatsby';
import {ChevronLeft} from 'react-feather';
import React from 'react';
import '../pages/changelog.css';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ChangelogTemplate = ({data: {markdownRemark}}) => {
  const {
    frontmatter: {title, imageAlt, imageUrl, date},
    html,
  } = markdownRemark;

  return (
    <Layout>
      <SEO title="Changelog - Fleet Serverless function fastest" />
      <div className="relative mt-12 md:mt-24 px-6 lg:px-12">
        <div className="container">
          <div className="flex flex-col w-full lg:w-4/6 lg:pr-12">
            <p className="text-gray mb-2">CHANGELOG</p>
            <h1 className="text-3xl sm:text-4xl md:text-5xl leading-tight font-semibold">
              New updates and improvements to Fleet
            </h1>
            <p className="text-gray mt-8 leading-relaxed">
              <a href="https://twitter.com/fleetfn" className="text-primary">
                Follow us on Twitter
              </a>{' '}
              for updates.
            </p>
          </div>
          <div className="grid gap-16 md:gap-32 lg:gap-48 w-full mt-16 md:mt-32 lg:mt-48">
            <div
              className="grid md:grid-cols-3 gap-4 w-full"
              key={date}
              id={date}
            >
              <div className="py-12">
                <Link
                  className="flex text-gray text-normal font-medium items-center hover:text-primary mb-12"
                  to="/changelog"
                >
                  <ChevronLeft size={20} className="mr-1" /> Back
                </Link>
                <p className="text-gray text-normal hover:text-primary">
                  {title}
                </p>
              </div>
              <div className="md:col-span-2">
                {imageUrl && (
                  <div className="w-full rounded-lg bg-gray-light">
                    <img
                      className="img rounded-lg"
                      alt={imageAlt}
                      src={imageUrl}
                    />
                  </div>
                )}
                <div
                  className="changelog-main"
                  dangerouslySetInnerHTML={{__html: html}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        date
        imageAlt
        imageUrl
        title: date(formatString: "YYYY / MMMM / DD")
      }
    }
  }
`;

export default ChangelogTemplate;
